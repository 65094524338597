.faq {
  padding: 80px 0px;
  position: relative;
  .circle_big {
    height: 90%;
    aspect-ratio: 1 / 1;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    z-index: -1;
    top: 50%;
    transform: translateY(-50%);
    .inner {
      height: 100%;
      width: 100%;
      border: 2px dashed var(--theme-yellow);
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      animation: circlemoveright 90s linear infinite;
    }
  }
  .circle_small {
    height: 45%;
    aspect-ratio: 1 / 1;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    z-index: -1;
    top: 50%;
    transform: translateY(-50%);
    .inner {
      height: 100%;
      width: 100%;
      border: 2px dashed var(--theme-yellow);
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      animation: circlemoveleft 90s linear infinite;
    }
  }
  .main_desc {
    margin: 18px 0px 26px 0px;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
  .faq_div {
    .accordion-button {
      color: var(--theme-darkgrey);
      background-color: var(--theme-white);
    }
    .accordion-button:not(.collapsed) {
      background-color: var(--theme-yellowbg);
      color: var(--theme-yellow);
      font-size: 16px;
      font-family: "medium";
      border: 1px solid;
    }
    .accordion-body {
      border: 1px solid var(--theme-yellow);
      border-top: none;
      font-size: 15px;
      color: var(--theme-lightgrey);
      background-color: var(--theme-white);
      p {
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        margin: 0px;
      }
    }
    .accordion-button:focus {
      box-shadow: none;
    }
  }
}
@keyframes circlemoveright {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes circlemoveleft {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
