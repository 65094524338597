.our_team {
  padding: 50px 0px;
  background-color: var(--theme-yellowbg);
  position: relative;
  margin-top: 230px;
  padding-top: 200px;
  .container.top_cont {
    position: absolute;
    top: -135px;
    left: 0;
    right: 0;
  }
  .team_member {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    .tm_single {
      border: 1px solid var(--theme-yellow);
      border-radius: 8px;
      overflow: hidden;
      img {
        width: 100%;
        aspect-ratio: 3/2.5;
        object-fit: cover;
        object-position: top;
      }
      .bottom {
        text-align: center;
        padding: 8px;
        h4 {
          font-size: 20px;
          font-family: "medium";
          color: var(--theme-darkgrey);
          margin-bottom: 6px;
        }
        h5 {
          font-size: 16px;
          font-family: regular;
          color: var(--theme-lightgrey);
          margin-bottom: 0;
        }
      }
    }
  }
  .our_team_right {
    border: 1px solid var(--theme-darkgrey);
    border-radius: 8px;
    overflow: hidden;
    padding: 8px;
    text-align: center;
    position: relative;
    padding-bottom: 40px;
    height: 100%;
    background: var(--theme-yellowbg);
    display: flex;
    align-items: center;
    justify-content: center;
    .main_desc {
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-transform: capitalize;
    }
    .theme_btn {
      border-radius: 0px;
      font-size: 16px;
      font-family: "regular";
      letter-spacing: 1.2px;
      position: absolute;
      bottom: 0;
      width: 101%;
      left: 0;
      text-align: center;
      padding: 8px;
    }
  }
  .social_medias{
    align-items: center;
    column-gap: 30px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    justify-content: center;
    img{
        width: 80px;
    }
  }

}
