.counter{
    padding: 80px 0px;
    .counter_card{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        .counter_single{
            box-shadow: 0px 15px 40px 0px rgba(0, 0, 100, 0.07);            
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 40px 15px;
            border-radius: 12px;
            .cs_inner{
                text-align: center;
                h5 {
                    font-size: 60px;
                    font-family: "title_regular";
                    color: var(--theme-darkgrey);
                    margin-bottom: 0px;
                    line-height: 52px;
                }
                h6 {
                    font-size: 17px;
                    font-family: 'regular';
                    color: var(--theme-lightgrey);
                    margin-bottom: 0px;
                }
                .wave {
                    height: 20px;
                    overflow: hidden;
                    margin-bottom: 12px;
                
                    span {
                        font-size: 100px;
                        font-variation-settings: "wght" 200;
                        line-height: 50px;
                        color: var(--theme-yellow);
                        
                    }
                }
              
            }
        }
    }
  
    .counter_right{
        margin-left: 30px;
        .video_parent{
            video {
                width: 100%;                 
                border-radius: 12px;
            }
        }
    }
}

  

  