section.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 9;
  &.headerhide {
    transition: 0.8s;
    opacity: 0;
    visibility: hidden;
  }
  &.headerfixedtop {
    transition: 0.8s;
    opacity: 1;
    visibility: visible;
    background: var(--theme-white);
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
    .header_inner {
      .header_logo {
        color: var(--theme-darkgrey);
      }
      .header_menus {
        .hm_single {
          color: var(--theme-darkgrey);
        }
      }
    }
  }
  .header_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 20px 0px;
    .header_logo {
      font-size: 24px;
      color: var(--theme-white);
      font-family: title_regular;
      letter-spacing: 2px;
    }
    .header_menus {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;

      .hm_single {
        font-size: 24px;
        color: var(--theme-white);
        font-family: title_regular;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 2px;
        &.active {
          color: var(--theme-red);
        }
      }
    }
    .header_btn {
      &.theme_btn {
        padding: 10px 30px;
        font-size: 15px;
        border: 1px solid;
        border-radius: 30px;
      }
    }
  }
}
