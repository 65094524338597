.services {
  padding: 80px 0px;
  background-color: var(--theme-yellowbg);
  .services_cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
    margin-top: 35px;
    .sc_single {
      display: flex;
      border: 1px solid var(--theme-yellow);
      border-radius: 10px;
      overflow: hidden;
      text-decoration: none;
      color: var(--theme-darkgrey);
      .left {
        width: 40%;      
        img {
          width: 100%;
          height: 100%;
          aspect-ratio: 1/0.9;
          object-fit: cover;
        }
      }
      .right {
        width: 60%;
        padding: 15px;
        position: relative;
        padding-bottom: 55px;
        h5 {
          font-family: "medium";
          font-size: 17px;
          margin-bottom: 10px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          line-height: 24px;
        }
        h6 {
          font-size: 15px;
          line-height: 22px;
          color: var(--theme-lightgrey);
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          margin-bottom: 0px;
        }
        .theme_btn {
          border-radius: 0px;
          font-size: 16px;
          font-family: "regular";
          letter-spacing: 1.2px;
          position: absolute;
          bottom: 0;
          width: 101%;
          left: 0;
          text-align: center;
          padding: 8px;
        }
      }
    }
  }
}
