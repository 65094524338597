.copyright{
    background: var(--theme-yellow);
    padding: 8px 0px;
    .copyright_inner{
        text-align: center;
        font-size: 18px;
        font-family: 'regular';
        color: var(--theme-white);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        a{
            text-decoration: none;
            color: var(--theme-darkgrey);
            font-family: medium;
        }
    }
}